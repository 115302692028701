import React, { useState, useEffect, useContext, createContext } from "react";

import moment from "moment";

import { serverDomain, period } from "../AppConfigs";

const BookingContext = createContext();
const axios = require("axios");

function useProvideBooking() {
    const [table, setTable] = useState(null);
    const [detail, setDetail] = useState(null);
    const [onProgress, setProgress] = useState(null);

    const getBooking = (date) => {
        axios
            .post(`${serverDomain}/booking/date`, {
                date: date,
            })
            .then(function (response) {
                let result = {};
                period.forEach((p, i) => {
                    let data = response.data.filter((v) => v.period === p);
                    result[`period[${i}]`] = data;
                });

                setTable(result);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {});
    };

    const getBookingDetail = (bookingId) => {
        axios
            .get(`${serverDomain}/booking/${bookingId}`)
            .then(function (response) {
                if (response.data) {
                    response.data.strDate = response.data.date;
                    response.data.date = moment(response.data.date);
                }
                setDetail(response.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {});
    };

    const saveBooking = (userId, data) => {
        data.userId = userId;

        console.log(data.date);

        let formatted_date =
            data.date.getFullYear() +
            "-" +
            (data.date.getMonth() + 1) +
            "-" +
            data.date.getDate();

        data.date = formatted_date;

        axios
            .post(`${serverDomain}/booking/add`, data)
            .then(function (response) {
                if (response.status === 201) {
                    setProgress(true);
                } else {
                    setProgress(false);
                }
            })
            .catch(function (error) {
                setProgress(false);
            })
            .then(function () {});
    };

    const updateBooking = (userId, bookingId, data) => {
        data.userId = userId;

        if (data.date.getFullYear) {
            let formatted_date =
                data.date.getFullYear() +
                "-" +
                (data.date.getMonth() + 1) +
                "-" +
                data.date.getDate();
            data.date = formatted_date;
        } else {
            data.date = data.strDate;
        }

        axios
            .put(`${serverDomain}/booking/${bookingId}`, data)
            .then(function (response) {
                if (response.status === 200) {
                    setProgress(true);
                } else {
                    setProgress(false);
                }
            })
            .catch(function (error) {
                setProgress(false);
            })
            .then(function () {});
    };

    const removeBooking = (bookingId) => {
        axios
            .delete(`${serverDomain}/booking/${bookingId}`)
            .then(function (response) {
                if (response.status === 200) {
                    setProgress(true);
                } else {
                    setProgress(false);
                }
            })
            .catch(function (error) {
                setProgress(false);
            })
            .then(function () {});
    };

    useEffect(() => {
        const unsubscribe = () => {
            setTable(null);
            setDetail(null);
            setProgress(null);
        };
        return () => unsubscribe();
    }, []);

    const clear = () => {
        setDetail(null);
        setProgress(null);
    };

    return {
        table,
        detail,
        onProgress,
        getBooking,
        getBookingDetail,
        saveBooking,
        updateBooking,
        removeBooking,
        clear,
    };
}

export function ProvideBooking({ children }) {
    const booking = useProvideBooking();
    return (
        <BookingContext.Provider value={booking}>
            {children}
        </BookingContext.Provider>
    );
}

export const useBooking = () => {
    return useContext(BookingContext);
};
