import React, { useState, useEffect, useContext, createContext } from "react";
import moment from "moment";
import { serverDomain } from "../AppConfigs";

const authContext = createContext();
const axios = require("axios");

function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [notification, setNotification] = useState(null);

    const getNotification = () => {
        axios
        .get(`${serverDomain}/notification`, {})
        .then(function (response) {
            setNotification({
                message: response.data.message,
                dateRange: [
                    moment(response.data.startDate)
                    ,moment(response.data.endDate)
                ]
            });
        })
        .catch(function (error) {
            console.log(error);
        })
        .then(function () {});
    };


    const signin = (data) => {
        axios
            .post(`${serverDomain}/user/login`, data)
            .then(function (response) {
                if (response.status === 201) {
                    setUser(response);
                } else {
                    setUser(false);
                }
            })
            .catch(function (error) {
                setUser(false);
            })
            .then(function () {});
    };

    const signout = () => {
        return setUser(null);
    };

    useEffect(() => {
        const unsubscribe = () => {
            setUser(null);
        };

        return () => unsubscribe();
    }, []);

    const clear = () => {
        setUser(null);
    };

    return {
        user,
        notification,
        getNotification,
        signin,
        signout,
        clear,
    };
}

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};
