import React from "react";

import { Row, Col, Form, Input, Button, Space, Select } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import { aircraftType, alertMsg } from "../AppConfigs";

import "../css/schedule.css";

const { Option } = Select;
const { TextArea } = Input;

export default function ScheduleItem({ fields, add, remove }) {
    return (
        <>
            {fields.map((field) => (
                <Row
                    style={{
                        textAlign: "center",
                        justify: "center",
                    }}
                >
                    <Col className="col-item" span={3}>
                        <Form.Item
                            {...field}
                            name={[field.name, "acNo"]}
                            fieldKey={[field.fieldKey, "acNo"]}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: alertMsg.requiredField,
                            //     },
                            // ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>
                    <Col className="col-item" span={3}>
                        <Form.Item
                            {...field}
                            name={[field.name, "area"]}
                            fieldKey={[field.fieldKey, "area"]}
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>
                    <Col className="col-item" span={4}>
                        <Form.Item
                            {...field}
                            name={[field.name, "sp"]}
                            fieldKey={[field.fieldKey, "sp"]}
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>
                    <Col className="col-item" span={4}>
                        <Form.Item
                            {...field}
                            name={[field.name, "ip"]}
                            fieldKey={[field.fieldKey, "ip"]}
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>
                    <Col className="col-item" span={3}>
                        <Form.Item
                            {...field}
                            name={[field.name, "aircraftType"]}
                            fieldKey={[field.fieldKey, "aircraftType"]}
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Select allowClear>
                                {aircraftType.map((item) => (
                                    <Option value={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="col-item" span={3}>
                        <Form.Item
                            {...field}
                            name={[field.name, "callSign"]}
                            fieldKey={[field.fieldKey, "callSign"]}
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>
                    <Col className="col-item" span={3}>
                        <Form.Item
                            {...field}
                            name={[field.name, "remark"]}
                            fieldKey={[field.fieldKey, "remark"]}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: alertMsg.requiredField,
                            //     },
                            // ]}
                        >
                            <TextArea rows={1} allowClear />
                        </Form.Item>
                    </Col>
                    <Col className="col-item" span={1}>
                        <Space align="center">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                size="small"
                                danger
                                disabled={false}
                                onClick={() => remove(field.name)}
                            />
                        </Space>
                    </Col>
                </Row>
            ))}
            <Row>
                <Col className="col-item" span={24}>
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                        >
                            Add Flight
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
