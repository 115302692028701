/* eslint-disable no-extend-native */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Row, Col, Form, Button, Space } from "antd";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSchedule } from "../hooks/useSchedule";
import { useLoading } from "../hooks/useLoading";

import { period } from "../AppConfigs";

import "../css/schedule.css";

Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

export function ScheduleView() {
    var cDate = new Date();
    var hours = cDate.getHours();
    var addDays = 0;

    if (hours >= 16) {
        addDays = 1;
    }

    const [date, changeDate] = useState(cDate.addDays(addDays));
    let schedule = useSchedule();

    useEffect(() => {
        schedule.getSchedule(date, true);
    }, [date]);

    return (
        <div className="schedule">
            <Row>
                <Col span={24}>
                    <div
                        style={{
                            margin: "0 auto 20px",
                            textAlign: "center",
                        }}
                    >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                orientation="landscape"
                                variant="inline"
                                openTo="date"
                                label="Date Picker : "
                                format="dd MMMM yyyy"
                                value={date}
                                onChange={changeDate}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Col>
            </Row>
            <Row
                style={{
                    textAlign: "center",
                    color: "white",
                }}
            >
                <Col className="col" span={3}>
                    A/C No.
                </Col>
                <Col className="col" span={3}>
                    Area/Destination
                </Col>
                <Col className="col" span={4}>
                    SP
                </Col>
                <Col className="col" span={4}>
                    IP
                </Col>
                <Col className="col" span={3}>
                    TYPE
                </Col>
                <Col className="col" span={3}>
                    Callsign
                </Col>
                <Col className="col" span={4}>
                    Remark
                </Col>
            </Row>
            {period.map((item, ind) => (
                <>
                    <Row>
                        <Col
                            style={{
                                backgroundColor: "rgb(132 174 242 / .2)",
                                borderColor: "#84aef2",
                                textAlign: "center",
                                padding: 10,
                            }}
                            span={24}
                        >
                            {item}
                        </Col>
                    </Row>

                    {schedule.detail &&
                    schedule.detail[`period[${ind}]`].length > 0 ? (
                        schedule.detail[`period[${ind}]`].map((item) => (
                            <Row
                                style={{
                                    textAlign: "center",
                                    padding: 10,
                                    justify: "center",
                                }}
                            >
                                <Col span={3}>{item.acNo}</Col>
                                <Col span={3}>{item.area}</Col>
                                <Col span={4}>{item.sp}</Col>
                                <Col span={4}>{item.ip}</Col>
                                <Col span={3}>{item.aircraftType}</Col>
                                <Col span={3}>Sunny {item.callSign}</Col>
                                <Col span={4}>{item.remark}</Col>
                            </Row>
                        ))
                    ) : (
                        <Row
                            style={{
                                textAlign: "center",
                                padding: 10,
                                justify: "center",
                            }}
                        >
                            <Col span={24}>ไม่พบข้อมูลการบิน</Col>
                        </Row>
                    )}
                </>
            ))}
        </div>
    );
}
