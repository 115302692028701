import { Layout, Menu, Button, Space, Typography, Alert } from "antd";
import {
    UserOutlined,
    CalendarOutlined,
    ScheduleOutlined,
    LogoutOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DiffOutlined,
    NotificationOutlined
} from "@ant-design/icons";

import { Switch, Route, Link, useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import moment from "moment";

import { useAuth } from "../hooks/useAuth";
import { ProvideUser } from "../hooks/useUser";
import { User } from "../component/User";
import { Booking } from "../component/Booking";
import { Schedule } from "../component/Schedule";
import { ScheduleView } from "../component/ScheduleView";
import { Notification } from "../component/Notification";
import { ProvideSchedule } from "../hooks/useSchedule";
import { ProvideNotification } from "../hooks/useNotification";

import { ProvideBooking } from "../hooks/useBooking";
import logo from "../img/logo.png";

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

export default function Login() {
    let auth = useAuth();
    let history = useHistory();
    let location = useLocation();

    const [showMenu, setMenu] = useState(true);
    const [showNotification, setNotification] = useState(false);

    let selectedKey = "1";
    if (location.pathname.match("user")) {
        selectedKey = "1";
    } else if (location.pathname.match("booking")) {
        selectedKey = "2";
    } else if (location.pathname.match("view")) {
        selectedKey = "5";
    } else if (location.pathname.match("notification")) {
        selectedKey = "6";
    } else {
        selectedKey = "3";
    }

    const toggleCollapsed = () => {
        setMenu(!showMenu);
    };

    useEffect(() => {}, [showMenu]);

    useEffect(() => {
        auth.getNotification();
    }, []);

    useEffect(() => {
        if(auth.notification) {
            var startDate = auth.notification.dateRange[0]
            var endDate = auth.notification.dateRange[1]

            if(startDate.isSame(moment(), 'day') || endDate.isSame(moment(), 'day')) {
                setNotification(true)
            }
            else {
                setNotification(false)
            }
        }
        
    }, [auth.notification]);

    return (
        <>
        <Layout style={{ minHeight: "100vh" }}>
            <Sider trigger={null} collapsible collapsed={showMenu}>
                <div
                    style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    <img width={60} src={logo} alt="logo" />
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[selectedKey]}
                >
                    {auth.user.data.role === "admin" && (
                        <Menu.Item key="3" icon={<DiffOutlined />}>
                            <Link to={`/schedule`}>จัดตารางการบิน</Link>
                        </Menu.Item>
                    )}
                    <Menu.Item key="5" icon={<ScheduleOutlined />}>
                        <Link to={`/view`}>ตารางการบิน</Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<CalendarOutlined />}>
                        <Link to={`/booking`}>ตารางการจองบิน</Link>
                    </Menu.Item>
                    {auth.user.data.role === "admin" && (
                        <Menu.Item key="1" icon={<UserOutlined />}>
                            <Link to={`/user`}>จัดการผู้ใช้งาน</Link>
                        </Menu.Item>
                    )}
                    {auth.user.data.role === "admin" && (
                        <Menu.Item key="6" icon={<NotificationOutlined />}>
                            <Link to={`/notification`}>แจ้งเตือนผู้ใช้งาน</Link>
                        </Menu.Item>
                    )}
                    <Menu.Item
                        key="4"
                        icon={<LogoutOutlined />}
                        onClick={() => {
                            auth.signout(() => history.push("/login"));
                        }}
                    >
                        Log out
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                {showNotification && (<Alert style={{ fontSize: 16 }}
                    message={'ประกาศ : ' + auth.notification.message}
                    banner
                    closable
                />)}
                <Header
                    className="site-layout-background"
                    style={{ paddingLeft: 10 }}
                >
                    <Button
                        onClick={toggleCollapsed}
                        style={{ marginBottom: 16 }}
                    >
                        {React.createElement(
                            showMenu ? MenuUnfoldOutlined : MenuFoldOutlined
                        )}
                    </Button>
                    <Space style={{ float: "right" }}>
                        <Button shape="circle" icon={<UserOutlined />} />
                        <Text>User Login : {auth.user.data.username}</Text>
                    </Space>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: "24px 16px",
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    <Switch>
                        <Route path={`/user`}>
                            <ProvideUser>
                                <User />
                            </ProvideUser>
                        </Route>
                        <Route path={`/booking`}>
                            <ProvideBooking>
                                <Booking />
                            </ProvideBooking>
                        </Route>
                        <Route path={`/schedule`}>
                            <ProvideSchedule>
                                <Schedule />
                            </ProvideSchedule>
                        </Route>
                        <Route path={`/view`}>
                            <ProvideSchedule>
                                <ScheduleView />
                            </ProvideSchedule>
                        </Route>
                        <Route path={`/notification`}>
                            <ProvideNotification>
                                <Notification />
                            </ProvideNotification>
                        </Route>
                    </Switch>
                </Content>
            </Layout>
        </Layout>

        </>
    );
}
