import { id } from "date-fns/locale";
import React, { useState, useEffect, useContext, createContext } from "react";

import { serverDomain, period } from "../AppConfigs";

const scheduleContext = createContext();
const axios = require("axios");

function useProvideSchedule() {
    const [detail, setSchedule] = useState(null);
    const [saveStatus, setSaveStatus] = useState(null);

    const getSchedule = (date, view = false) => {
        let formatted_date =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();

        if (view === true) {
            var cDate = new Date();
            var hours = cDate.getHours();

            var diffDate = Math.floor(
                (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
                    Date.UTC(
                        cDate.getFullYear(),
                        cDate.getMonth(),
                        cDate.getDate()
                    )) /
                    (1000 * 60 * 60 * 24)
            );

            if (diffDate === 1 && hours >= 16) {
                axios
                    .post(`${serverDomain}/schedule`, {
                        date: formatted_date,
                    })
                    .then(function (response) {
                        let result = {};
                        period.forEach((p, i) => {
                            let data = response.data.filter(
                                (v) => v.period === p
                            );
                            result[`period[${i}]`] = data;
                        });

                        setSchedule(result);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .then(function () {});
            } else if (diffDate < 1) {
                axios
                    .post(`${serverDomain}/schedule`, {
                        date: formatted_date,
                    })
                    .then(function (response) {
                        let result = {};
                        period.forEach((p, i) => {
                            let data = response.data.filter(
                                (v) => v.period === p
                            );
                            result[`period[${i}]`] = data;
                        });

                        setSchedule(result);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .then(function () {});
            } else {
                let result = {};
                period.forEach((p, i) => {
                    result[`period[${i}]`] = [];
                });

                setSchedule(result);
            }
        } else {
            axios
                .post(`${serverDomain}/schedule`, {
                    date: formatted_date,
                })
                .then(function (response) {
                    let result = {};
                    period.forEach((p, i) => {
                        let data = response.data.filter((v) => v.period === p);
                        result[`period[${i}]`] = data;
                    });

                    setSchedule(result);
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {});
        }
    };

    const saveSchedule = (date, data) => {
        let insertDate = [];
        period.forEach((p, i) => {
            if (data[`period[${i}]`].length > 0) {
                data[`period[${i}]`].forEach((item) => {
                    item.date = date;
                    item.period = p;

                    insertDate.push(item);
                });
            }
        });

        axios
            .post(`${serverDomain}/schedule/update`, {
                date: date,
                schedule: insertDate,
            })
            .then(function (response) {
                if (response.status === 201) {
                    setSaveStatus(true);
                } else {
                    setSaveStatus(false);
                }
            })
            .catch(function (error) {
                setSaveStatus(false);
            })
            .then(function () {});
    };

    const clearStatus = () => {
        setSaveStatus(null);
    };

    useEffect(() => {
        const unsubscribe = () => {
            setSchedule(null);
            setSaveStatus(null);
        };
        return () => unsubscribe();
    }, []);

    return {
        detail,
        getSchedule,
        saveStatus,
        saveSchedule,
        clearStatus,
    };
}

export function ProvideSchedule({ children }) {
    const schedule = useProvideSchedule();
    return (
        <scheduleContext.Provider value={schedule}>
            {children}
        </scheduleContext.Provider>
    );
}

export const useSchedule = () => {
    return useContext(scheduleContext);
};
