import React, { useState, useEffect, useContext, createContext } from "react";

const loadingContext = createContext();

function useProvideLoading() {
    const [show, setLoading] = useState(null);

    useEffect(() => {
        const unsubscribe = () => {
            setLoading(null);
        };

        return () => unsubscribe();
    }, []);

    return {
        show,
        setLoading,
    };
}

export function ProvideLoading({ children }) {
    const loading = useProvideLoading();
    return (
        <loadingContext.Provider value={loading}>
            {children}
        </loadingContext.Provider>
    );
}

export const useLoading = () => {
    return useContext(loadingContext);
};
