import { id } from "date-fns/locale";
import React, { useState, useEffect, useContext, createContext } from "react";

import moment from "moment";

import { serverDomain, period } from "../AppConfigs";

const notificationContext = createContext();
const axios = require("axios");

function useProvideNotification() {
    const [detail, setNotification] = useState(null);
    const [saveStatus, setSaveStatus] = useState(null);

    const getNotification = () => {
        axios
        .get(`${serverDomain}/notification`, {})
        .then(function (response) {
            setNotification({
                message: response.data.message,
                dateRange: [
                    moment(response.data.startDate)
                    ,moment(response.data.endDate)
                ]
            });
        })
        .catch(function (error) {
            console.log(error);
        })
        .then(function () {});
    };

    const saveNotification = (message, dateRange) => {

        let startDate = dateRange?.[0]?.format()
        let endDate = dateRange?.[1]?.format()

        axios
            .put(`${serverDomain}/notification`, {
                message: message,
                startDate: startDate,
                endDate: endDate,
            })
            .then(function (response) {
                if (response.status === 200) {
                    setSaveStatus(true);
                } else {
                    setSaveStatus(false);
                }
            })
            .catch(function (error) {
                setSaveStatus(false);
            })
            .then(function () {});
    };

    const clearStatus = () => {
        setSaveStatus(null);
    };

    useEffect(() => {
        const unsubscribe = () => {
            setNotification(null);
            setSaveStatus(null);
        };
        return () => unsubscribe();
    }, []);

    return {
        detail,
        getNotification,
        saveStatus,
        saveNotification,
        clearStatus,
    };
}

export function ProvideNotification({ children }) {
    const notification = useProvideNotification();
    return (
        <notificationContext.Provider value={notification}>
            {children}
        </notificationContext.Provider>
    );
}

export const useNotification = () => {
    return useContext(notificationContext);
};
