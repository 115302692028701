import "./App.css";
import "antd/dist/antd.css";

import React from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import Login from "./component/Login";
import Register from "./component/Register";
import Main from "./component/Main";
import { ProvideAuth, useAuth } from "./hooks/useAuth";
import { ProvideUser } from "./hooks/useUser";
import { ProvideLoading } from "./hooks/useLoading";
import { Loading } from "./component/Loading";

function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export default function App() {
    return (
        <ProvideLoading>
            <Loading>
                <ProvideAuth>
                    <Router>
                        <Switch>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <Route path="/register">
                                <ProvideUser>
                                    <Register />
                                </ProvideUser>
                            </Route>
                            <PrivateRoute path="/">
                                <Main />
                            </PrivateRoute>
                        </Switch>
                    </Router>
                </ProvideAuth>
            </Loading>
        </ProvideLoading>
    );
}
