import React, { useState, useEffect, useContext, createContext } from "react";

import { serverDomain } from "../AppConfigs";

const userContext = createContext();
const axios = require("axios");

function useProvideUser() {
    const [table, setTable] = useState(null);
    const [filterTable, setFilter] = useState(null);
    const [detail, setDetail] = useState(null);
    const [onProgress, setProgress] = useState(null);

    const getUser = (token) => {
        axios
            .get(`${serverDomain}/user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (response) {
                setTable(response.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {});
    };

    const getUserDetail = (userId) => {
        axios
            .get(`${serverDomain}/user/${userId}`)
            .then(function (response) {
                response.data.password = undefined;
                setDetail(response.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {});
    };

    const saveUser = (data) => {
        axios
            .post(`${serverDomain}/user/add`, data)
            .then(function (response) {
                if (response.status === 201) {
                    setProgress(true);
                } else {
                    setProgress(false);
                }
            })
            .catch(function (error) {
                setProgress(false);
            })
            .then(function () {});
    };

    const updateUser = (userId, data) => {
        axios
            .put(`${serverDomain}/user/${userId}`, data)
            .then(function (response) {
                if (response.status === 200) {
                    setProgress(true);
                } else {
                    setProgress(false);
                }
            })
            .catch(function (error) {
                setProgress(false);
            })
            .then(function () {});
    };

    const removeUser = (userId) => {
        axios
            .delete(`${serverDomain}/user/${userId}`)
            .then(function (response) {
                if (response.status === 200) {
                    setProgress(true);
                } else {
                    setProgress(false);
                }
            })
            .catch(function (error) {
                setProgress(false);
            })
            .then(function () {});
    };

    const search = (value) => {
        if (value) {
            const data = table.filter((o) =>
                Object.keys(o).some((k) => {
                    if (
                        k === "username" ||
                        k === "firstName" ||
                        k === "lastName" ||
                        k === "classNo"
                    )
                        return String(o[k])
                            .toLowerCase()
                            .includes(value.toLowerCase());
                })
            );
            setFilter(data);
        } else {
            setFilter(null);
        }
    };

    useEffect(() => {
        const unsubscribe = () => {
            setTable(null);
            setDetail(null);
        };
        return () => unsubscribe();
    }, []);

    const clear = () => {
        setDetail(null);
        setProgress(null);
        setFilter(null);
    };

    return {
        table,
        filterTable,
        detail,
        onProgress,
        getUser,
        getUserDetail,
        saveUser,
        updateUser,
        removeUser,
        search,
        clear,
    };
}

export function ProvideUser({ children }) {
    const user = useProvideUser();
    return <userContext.Provider value={user}>{children}</userContext.Provider>;
}

export const useUser = () => {
    return useContext(userContext);
};
