/* eslint-disable no-extend-native */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Row, Col, Form, Button, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ScheduleItem from "./ScheduleItem";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSchedule } from "../hooks/useSchedule";
import { useLoading } from "../hooks/useLoading";

import { alertMsg } from "../AppConfigs";

import "../css/schedule.css";

const { confirm } = Modal;

Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

export function Schedule() {
    const [form] = Form.useForm();
    var cDate = new Date();
    var hours = cDate.getHours();
    var addDays = 0;

    if (hours >= 16) {
        addDays = 1;
    }

    const [date, changeDate] = useState(cDate.addDays(addDays));
    let schedule = useSchedule();
    let loading = useLoading();

    const cancel = () => {
        confirm({
            title: alertMsg.confirmCancel,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                loading.setLoading(true);
                schedule.getSchedule(date);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const onFinish = (values) => {
        let formatted_date =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();

        confirm({
            title: alertMsg.confirmSave,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                loading.setLoading(true);
                schedule.saveSchedule(formatted_date, values);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    useEffect(() => {
        schedule.getSchedule(date);
    }, [date]);

    useEffect(() => {
        if (schedule.detail) {
            loading.setLoading(false);
        }
        form.setFieldsValue(schedule.detail);
    }, [form, schedule.detail]);

    useEffect(() => {
        if (schedule.saveStatus === true) {
            Modal.success({
                content: alertMsg.saveSucces,
            });
            loading.setLoading(false);
            schedule.clearStatus();
        } else if (schedule.saveStatus === false) {
            Modal.error({
                content: alertMsg.saveFail,
            });
            loading.setLoading(false);
            schedule.clearStatus();
        }
    }, [schedule.saveStatus]);

    return (
        <div className="schedule">
            <Row>
                <Col span={24}>
                    <div
                        style={{
                            margin: "0 auto 20px",
                            textAlign: "center",
                        }}
                    >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                orientation="landscape"
                                variant="inline"
                                openTo="date"
                                label="Date Picker : "
                                format="dd MMMM yyyy"
                                value={date}
                                onChange={changeDate}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Col>
            </Row>
            <Row
                style={{
                    textAlign: "center",
                    color: "white",
                }}
            >
                <Col className="col" span={3}>
                    A/C No.
                </Col>
                <Col className="col" span={3}>
                    Area/Destination
                </Col>
                <Col className="col" span={4}>
                    SP
                </Col>
                <Col className="col" span={4}>
                    IP
                </Col>
                <Col className="col" span={3}>
                    TYPE
                </Col>
                <Col className="col" span={3}>
                    Callsign
                </Col>
                <Col className="col" span={4}>
                    Remark
                </Col>
            </Row>
            <Form form={form} onFinish={onFinish} name="schedule-detail">
                <Row>
                    <Col className="period" span={24}>
                        06:30 - 07:30
                    </Col>
                </Row>
                <Form.List name="period[0]">
                    {(fields, { add, remove }) => (
                        <>
                            <ScheduleItem
                                fields={fields}
                                add={add}
                                remove={remove}
                            />
                        </>
                    )}
                </Form.List>
                <Row>
                    <Col className="period" span={24}>
                        07:30 - 08:30
                    </Col>
                </Row>
                <Form.List name="period[1]">
                    {(fields, { add, remove }) => (
                        <>
                            <ScheduleItem
                                fields={fields}
                                add={add}
                                remove={remove}
                            />
                        </>
                    )}
                </Form.List>
                <Row>
                    <Col className="period" span={24}>
                        09:30 - 10:30
                    </Col>
                </Row>
                <Form.List name="period[2]">
                    {(fields, { add, remove }) => (
                        <>
                            <ScheduleItem
                                fields={fields}
                                add={add}
                                remove={remove}
                            />
                        </>
                    )}
                </Form.List>
                <Row>
                    <Col className="period" span={24}>
                        11:30 - 12:30
                    </Col>
                </Row>
                <Form.List name="period[3]">
                    {(fields, { add, remove }) => (
                        <>
                            <ScheduleItem
                                fields={fields}
                                add={add}
                                remove={remove}
                            />
                        </>
                    )}
                </Form.List>
                <Row>
                    <Col className="period" span={24}>
                        13:30 - 14:30
                    </Col>
                </Row>
                <Form.List name="period[4]">
                    {(fields, { add, remove }) => (
                        <>
                            <ScheduleItem
                                fields={fields}
                                add={add}
                                remove={remove}
                            />
                        </>
                    )}
                </Form.List>
                
                <Row style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <Form.Item
                            style={{
                                marginBottom: 0,
                                textAlign: "center",
                            }}
                        >
                            <Space>
                                <Button
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    type="primary"
                                    danger
                                    onClick={cancel}
                                >
                                    Cancel
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
