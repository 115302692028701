import React, { useEffect } from "react";

import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Select,
    Typography,
    Space,
    Modal,
} from "antd";
import {
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

import { useParams, useHistory } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { useLoading } from "../hooks/useLoading";

import { alertMsg } from "../AppConfigs";

const { Option } = Select;
const { Title } = Typography;
const { confirm } = Modal;

export default function UserDetail() {
    let { userId } = useParams();
    let history = useHistory();
    let user = useUser();
    let loading = useLoading();

    useEffect(() => {
        user.getUserDetail(userId);
    }, []);

    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue(user.detail);
    }, [form, user.detail]);

    function back() {
        confirm({
            title: alertMsg.confirmCancel,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                user.clear();
                return history.push("/user");
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    useEffect(() => {
        if (user.onProgress !== null) {
            loading.setLoading(false);
            if (user.onProgress === true) {
                history.push("/user");
            }
            user.clear();
        }
    }, [user.onProgress]);

    function onFinish(values) {
        console.log(values);
        confirm({
            title: alertMsg.confirmSave,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                loading.setLoading(true);
                user.updateUser(userId, values);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    return (
        <React.Fragment>
            <Row align="left">
                <Col xs={24} xl={24}>
                    <Form
                        name="user-detail"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        layout="horizontal"
                        initialValues={user.detail}
                        form={form}
                        onFinish={onFinish}
                    >
                        <Form.Item>
                            <Button
                                type="primary"
                                icon={
                                    <ArrowLeftOutlined
                                        style={{ color: "white" }}
                                    />
                                }
                                size="large"
                                onClick={back}
                            >
                                จัดการผู้ใช้งาน
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Title level={4}>
                                ข้อมูลสมาชิกศิษย์การบินพลเรือนกองทัพอากาศ
                            </Title>
                        </Form.Item>
                        <Form.Item
                            label="รุ่นที่"
                            name="classNo"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="ชื่อ Login"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item label="รหัสผ่าน" name="password">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="ชื่อ"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="นามสกุล"
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            name="gender"
                            label="เพศ"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Select allowClear>
                                <Option value="male">ชาย</Option>
                                <Option value="female">หญิง</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="อายุ"
                            name="age"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="ที่อยู่"
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="จังหวัด"
                            name="province"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="รหัสไปรณีย์"
                            name="postcode"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="เบอร์โทร"
                            name="phoneNumber"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="อาชีพ"
                            name="career"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="อีเมล"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="เลขบัตรประชาชน"
                            name="idCode"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item
                            label="สถานะ"
                            name="active"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Select allowClear>
                                <Option value="1">เปิด</Option>
                                <Option value="0">ปิด</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{
                                marginBottom: 0,
                                textAlign: "center",
                            }}
                        >
                            <Space>
                                <Button
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    size="large"
                                    type="primary"
                                    danger
                                    onClick={back}
                                >
                                    ยกเลิก
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>
    );
}
