import React, { useEffect, useState } from "react";

import { Layout, Form, Input, Button, Row, Col, Modal, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { useAuth } from "../hooks/useAuth";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import logo from "../img/logo.png";

import moment from "moment";

import "../css/login.css";

export default function Login() {
    let history = useHistory();
    let auth = useAuth();

    const [showNotification, setNotification] = useState(false);

    const onFinish = (values) => {
        auth.signin(values);
    };

    useEffect(() => {
        if (auth.user) {
            if (auth.user.data.role === "admin") {
                history.push("/schedule");
            } else {
                history.push("/view");
            }
        } else if (auth.user === false) {
            Modal.error({
                content: "Username หรือ Password ไม่ถูกต้อง",
            });
            auth.clear();
        }
    }, [auth.user]);

    useEffect(() => {
        auth.getNotification();
    }, []);

    useEffect(() => {
        if(auth.notification) {
            var startDate = auth.notification.dateRange[0]
            var endDate = auth.notification.dateRange[1]

            if(startDate.isSame(moment(), 'day') || endDate.isSame(moment(), 'day')) {
                setNotification(true)
            }
            else {
                setNotification(false)
            }
        }
        
    }, [auth.notification]);

    return (
        <React.Fragment>
            
            <Layout className="login-layout">
                
                <Row
                    align="middle"
                    justify="center"
                    style={{ minHeight: "100vh" }}
                >
                    <Col
                        xs={22}
                        sm={18}
                        md={14}
                        lg={10}
                        xl={6}
                        style={{
                            padding: 40,
                            backgroundColor: "white",
                            boxShadow: "0 0 0.875rem 0 rgba(41,48,66,.1)",
                            borderRadius: ".25rem",
                        }}
                    >
                        {showNotification && (<Alert
                            message={'ประกาศ : ' + auth.notification.message}
                            type="warning"
                            showIcon
                            closable
                        />)}
                        <Form
                            name="normal_login"
                            className="login-form"
                            layout="vertical"
                            initialValues={{}}
                            onFinish={onFinish}
                        >
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <img width={140} src={logo} alt="logo" />
                            </div>
                            <Form.Item label="Username" name="username">
                                <Input
                                    size="large"
                                    prefix={
                                        <UserOutlined className="site-form-item-icon" />
                                    }
                                    placeholder="Enter your username"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item label="Password" name="password">
                                <Input.Password
                                    size="large"
                                    prefix={
                                        <LockOutlined className="site-form-item-icon" />
                                    }
                                    placeholder="Enter your password"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item>
                                <Link
                                    style={{
                                        float: "right",
                                        marginTop: 20,
                                        marginRight: 5,
                                    }}
                                    to={`/register`}
                                >
                                    Register
                                </Link>
                            </Form.Item>

                            <Form.Item style={{ marginBottom: 0 }}>
                                <Button
                                    block
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Layout>
        </React.Fragment>
    );
}
