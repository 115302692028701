import React from "react";

import { Row, Col, Space, Button } from "antd";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { useHistory } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

import { period } from "../AppConfigs";

import "../css/booking.css";

export default function BookingTable({ user, data, remove }) {
    let history = useHistory();
    let auth = useAuth();

    return (
        <div>
            <Row
                style={{
                    textAlign: "center",
                    color: "white",
                }}
            >
                <Col className="col" span={3}>
                    TYPE
                </Col>
                <Col className="col" span={5}>
                    ชื่อศิษย์
                </Col>
                <Col className="col" span={5}>
                    ชื่อครู
                </Col>
                <Col className="col" span={4}>
                    พื้นที่
                </Col>
                <Col className="col" span={6}>
                    หมายเหตุ
                </Col>
                <Col className="col" span={1}></Col>
            </Row>

            {period.map((item, ind) => (
                <>
                    <Row>
                        <Col
                            style={{
                                backgroundColor: "rgb(132 174 242 / .2)",
                                borderColor: "#84aef2",
                                textAlign: "center",
                                padding: 10,
                            }}
                            span={24}
                        >
                            {item}
                        </Col>
                    </Row>

                    {data && data[`period[${ind}]`].length > 0 ? (
                        data[`period[${ind}]`].map((item) => (
                            <Row
                                style={{
                                    textAlign: "center",
                                    padding: 10,
                                    justify: "center",
                                }}
                            >
                                <Col span={3}>{item.aircraftType}</Col>
                                <Col span={5}>
                                    {item.user?.firstName} {item.user?.lastName}
                                </Col>
                                <Col span={5}>ครู{item.instrutor}</Col>
                                <Col span={4}>{item.area}</Col>
                                <Col span={6}>{item.remark}</Col>
                                <Col span={1}>
                                    {user.id === item.userId && (
                                        <Space align="center">
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<EditOutlined />}
                                                size="small"
                                                disabled={false}
                                                onClick={() =>
                                                    history.push(
                                                        `/booking/${item.id}`
                                                    )
                                                }
                                            />
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<DeleteOutlined />}
                                                size="small"
                                                danger
                                                disabled={false}
                                                onClick={() => remove(item.id)}
                                            />
                                        </Space>
                                    )}
                                    {auth.user.data.role === "admin" && (
                                        <Space align="center">
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<DeleteOutlined />}
                                                size="small"
                                                danger
                                                disabled={false}
                                                onClick={() => remove(item.id)}
                                            />
                                        </Space>
                                    )}
                                </Col>
                            </Row>
                        ))
                    ) : (
                        <Row
                            style={{
                                textAlign: "center",
                                padding: 10,
                                justify: "center",
                            }}
                        >
                            <Col span={24}>ไม่พบข้อมูลการจอง</Col>
                        </Row>
                    )}
                </>
            ))}
        </div>
    );
}
