import React from "react";
import { Spin } from "antd";

import { useLoading } from "../hooks/useLoading";

export function Loading({ children }) {
    let loading = useLoading();

    return (
        <Spin spinning={loading.show || false} delay={500} size="large">
            {children}
        </Spin>
    );
}
