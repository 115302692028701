import React, { useEffect } from "react";

import {
    Layout,
    Form,
    Input,
    Button,
    Row,
    Col,
    Select,
    Typography,
    Space,
    Modal,
} from "antd";

import { ArrowLeftOutlined } from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { useLoading } from "../hooks/useLoading";

import { Link } from "react-router-dom";

import { alertMsg } from "../AppConfigs";

import "../css/register.css";

const { Option } = Select;
const { Title, Text } = Typography;

export default function Register() {
    let history = useHistory();
    let user = useUser();
    let loading = useLoading();

    const onFinish = (values) => {
        if (values.password === values.cpassword) {
            loading.setLoading(true);
            user.saveUser(values);
        } else {
            Modal.error({
                content: "รหัสผ่านไม่ตรงกัน",
            });
        }
    };

    useEffect(() => {
        if (user.onProgress !== null) {
            loading.setLoading(false);

            if (user.onProgress === true) {
                Modal.success({
                    content: alertMsg.registerSucces,
                    onOk() {
                        history.push("/login");
                    },
                });
                loading.setLoading(false);
                user.clear();
            } else if (user.onProgress === false) {
                Modal.error({
                    content: alertMsg.saveFail,
                });
                loading.setLoading(false);
                user.clear();
            }
        }
    }, [user.onProgress]);

    return (
        <React.Fragment>
            <Layout className="site-layout" style={{ height: "100%" }}>
                <Button
                    type="link"
                    style={{
                        textAlign: "left",
                        marginTop: 20,
                        marginLeft: 15,
                        color: "#00000",
                    }}
                    icon={<ArrowLeftOutlined />}
                >
                    <Link to={`/login`}> Log in</Link>
                </Button>
                <Row
                    align="top"
                    justify="center"
                    style={{ height: "90%", overflow: "hidden" }}
                >
                    <Col
                        xs={22}
                        xl={10}
                        style={{
                            padding: 40,
                            backgroundColor: "white",
                            boxShadow: "0 0 0.875rem 0 rgba(41,48,66,.1)",
                            borderRadius: ".25rem",
                            height: "100%",
                            overflow: "auto",
                        }}
                    >
                        <Title level={2}>
                            ข้อมูลสมาชิกศิษย์การบินพลเรือนกองทัพอากาศ
                        </Title>
                        <Form
                            name="normal_register"
                            layout="vertical"
                            initialValues={{}}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="รุ่นที่"
                                name="classNo"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                label="ชื่อ Login"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                label="รหัสผ่าน"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input.Password allowClear />
                            </Form.Item>
                            <Form.Item
                                label="ยืนยันรหัสผ่าน"
                                name="cpassword"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input.Password allowClear />
                            </Form.Item>
                            <Form.Item
                                label="ชื่อ"
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                label="นามสกุล"
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                name="gender"
                                label="เพศ"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Select allowClear>
                                    <Option value="male">ชาย</Option>
                                    <Option value="female">หญิง</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="อายุ"
                                name="age"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                label="ที่อยู่"
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                label="จังหวัด"
                                name="province"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                label="รหัสไปรณีย์"
                                name="postcode"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                label="เบอร์โทร"
                                name="phoneNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                label="อาชีพ"
                                name="career"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                label="อีเมล"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                label="เลขบัตรประชาชน"
                                name="idCode"
                                rules={[
                                    {
                                        required: true,
                                        message: alertMsg.requiredField,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>

                            <Form.Item
                                style={{
                                    marginBottom: 0,
                                    textAlign: "center",
                                }}
                            >
                                <Button
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    ลงทะเบียน
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>

                    <Col
                        xs={22}
                        xl={10}
                        style={{
                            padding: 40,
                            height: "80%",
                            overflow: "auto",
                        }}
                    >
                        <Space direction="vertical">
                            <Title style={{ color: "#2e6fab" }} level={3}>
                                เงื่อนไขการสมัครสมาชิก
                            </Title>
                            <Text type="danger">
                                -
                                ผู้สมัครต้องเป็นสมาชิกของหน่วยฝึกการบินพลเรือนกองทัพอากาศเท่านั้น
                                จึงจะสามารถทำการสมัครสมาชิกฯ ได้
                            </Text>
                            <Text type="danger">
                                - ถ้าตรวจพบว่าข้อมูลที่แจ้งไว้เป็นเท็จ
                                ก็จะทำการยกเลิกการเป็นสมาชิกฯ
                                ทันทีและจะดำเนินการทางกฎหมายด้วย
                            </Text>
                            <Text style={{ color: "#2e6fab" }}>
                                "เงื่อนไขในการตั้งชื่อเพื่อสำหรับ Login
                                จะต้องตั้งชื่อด้วยเลขรุ่นสมาชิกฯตามด้วย _ (under
                                score) แล้วตามด้วยชื่อจริง
                            </Text>
                            <Text style={{ color: "#2e6fab" }}>
                                เช่น 63_Wanchai (ชื่อวันชัย เป็นสมาชิกฯ รุ่นที่
                                63) ถ้าสมาชิกใช้ชื่อสมัครไม่เป็นไปตามเงื่อนไข
                                ขอสงวนสิทธิ์ไม่อนุมัติการสมัคร"
                            </Text>
                        </Space>
                    </Col>
                </Row>
            </Layout>
        </React.Fragment>
    );
}
