export const aircraftType = ["CT-4", "DA-40", "DA-42", "P180", "บ.ทอ.6"];

export const period = [
    "06:30 - 07:30",
    "07:30 - 08:30",
    "09:30 - 10:30",
    "11:30 - 12:30",
    "13:30 - 14:30",
];

export const mission = [
    "Contact",
    "Instrument",
    "Navigation",
    "Formation",
    "Check",
];

export const area = [
    "D72",
    "กำแพงแสน",
    "โคกกระเทียม",
    "ตาคลี",
    "หัวหิน",
    "อู่ตะเภา",
    "วัฒนานคร",
    "อื่นๆ (ใส่เพิ่มเติมในหมายเหตุ)",
];

export const alertMsg = {
    requiredField: "กรุณาระบุข้อมูล",
    confirmSave: "ยืนยันการบันทึกข้อมูล ?",
    confirmCancel: "ยืนยันยกเลิกการแก้ไขข้อมูล ?",
    confirmRemove: "ยืนยันการลบข้อมูล ?",
    saveSucces: "บันทึกข้อมูลสำเร็จ",
    saveFail: "เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้",
    registerSucces: "สมัครสมาชิกสำเร็จ",
    msgBooking:
        "*** - เมื่อต้องการจองบินเดินทางต่างจังหวัดและ Ops. ต้องแจ้งให้ น.ท.เกียรติศักดิ์ แจ่มใจ ผบ.ฝูง.604 โทร.095-554-4244 และ น.ต.สุรนัย ไชยพันธ์ นยก.ฝยก.ฝูง.604 โทร.082-449-9693 ทราบเท่านั้น -หากสมาชิกฯ มีข้อสงสัยหรือข้อขัดข้องในการจองบิน ติดต่อ น.จัดบิน ร.อ.นรวันทย์ โหมาศวิน 083-915-6756",
};

//export const serverDomain = "http://localhost:3000";
export const serverDomain = "http://api.604sqn.com";
