import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { useAuth } from "../hooks/useAuth";
import { useLoading } from "../hooks/useLoading";
import UserDetail from "./UserDetail";

import {
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

import { Table, Button, Space, Modal, Input } from "antd";
import { alertMsg } from "../AppConfigs";
import { useHistory } from "react-router-dom";

const { confirm } = Modal;

export function User() {
    let user = useUser();
    let auth = useAuth();
    let history = useHistory();
    let { path } = useRouteMatch();
    let loading = useLoading();

    useEffect(() => {
        loading.setLoading(true);
        user.getUser(auth.user.data.access_token);
    }, []);

    useEffect(() => {
        if (user.table) loading.setLoading(false);
    }, [user.table]);

    useEffect(() => {
        if (user.onProgress !== null) {
            loading.setLoading(false);

            user.getUser(auth.user.data.access_token);
            user.clear();
        }
    }, [user.onProgress]);

    function remove(userId) {
        confirm({
            title: alertMsg.confirmRemove,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                loading.setLoading(true);
                user.removeUser(userId);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    function search(value) {
        user.search(value);
    }

    useEffect(() => {}, [user.filterTable]);

    const columns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
            sorter: (a, b) => a.username - b.username,
        },
        {
            title: "ชื่อ",
            dataIndex: "firstName",
            key: "firstName",
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        },
        {
            title: "นามสกุล",
            dataIndex: "lastName",
            key: "lastName",
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        },
        {
            title: "รุ่นที่",
            dataIndex: "classNo",
            key: "classNo",
            sorter: (a, b) => a.classNo - b.classNo,
        },
        {
            title: "Active",
            key: "active",
            sorter: (a, b) => a.active - b.active,
            render: (text, row) => {
                if (text.active === "1") {
                    return <CheckOutlined style={{ color: "green" }} />;
                } else {
                    return <CloseOutlined style={{ color: "red" }} />;
                }
            },
        },
        {
            title: "Action",
            key: "action",
            render: (text, row) => (
                <Space align="center">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        size="small"
                        disabled={false}
                        onClick={() => history.push(`${path}/${row.id}`)}
                    />
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        size="small"
                        danger
                        disabled={false}
                        onClick={() => remove(text.id)}
                    />
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Switch>
                <Route exact path={path}>
                    <Input.Search
                        style={{
                            margin: "0 0 10px 0",
                        }}
                        placeholder="Search by..."
                        enterButton
                        onSearch={search}
                    />
                    {console.log(user.filterTable, user.table)}
                    <Table
                        columns={columns}
                        dataSource={
                            user.filterTable == null
                                ? user.table
                                : user.filterTable
                        }
                        pagination={{ pageSize: 10 }}
                        //scroll={{ y: 100 }}
                    />
                </Route>
                <Route path={`${path}/add`}>
                    <UserDetail />
                </Route>
                <Route path={`${path}/:userId`}>
                    <UserDetail />
                </Route>
            </Switch>
        </div>
    );
}
