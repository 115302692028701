/* eslint-disable no-extend-native */
import React, { useEffect, useState } from "react";

import { Row, Col, Space, Button, Modal } from "antd";
import { FormOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import BookingTable from "./BookingTable";
import BookingDetail from "./BookingDetail";

import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { useBooking } from "../hooks/useBooking";
import { useLoading } from "../hooks/useLoading";
import { useAuth } from "../hooks/useAuth";

import { alertMsg } from "../AppConfigs";

import "../css/booking.css";

const { confirm } = Modal;

Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

export function Booking() {
    var cDate = new Date();
    var hours = cDate.getHours();
    var addDays = 0;

    if (hours >= 16) {
        addDays = 1;
    }

    const [date, changeDate] = useState(cDate.addDays(addDays));
    let booking = useBooking();
    let { path } = useRouteMatch();
    let history = useHistory();
    let loading = useLoading();
    let auth = useAuth();

    useEffect(() => {
        let formatted_date =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
        loading.setLoading(true);
        booking.getBooking(formatted_date);
    }, [date]);

    useEffect(() => {
        if (booking.table) loading.setLoading(false);
    }, [booking.table]);

    useEffect(() => {
        if (booking.onProgress !== null) {
            loading.setLoading(false);
            let formatted_date =
                date.getFullYear() +
                "-" +
                (date.getMonth() + 1) +
                "-" +
                date.getDate();
            booking.getBooking(formatted_date);
            booking.clear();
        }
    }, [booking.onProgress]);

    function remove(bookingId) {
        confirm({
            title: alertMsg.confirmRemove,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                loading.setLoading(true);
                booking.removeBooking(bookingId);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    return (
        <div className="booking">
            <Switch>
                <Route exact path={path}>
                    <Row>
                        <Col span={24}>
                            <div
                                style={{
                                    margin: "0 auto 20px",
                                    textAlign: "center",
                                }}
                            >
                                <Space>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <DatePicker
                                            autoOk
                                            orientation="landscape"
                                            variant="inline"
                                            openTo="date"
                                            label="Date Picker : "
                                            format="dd MMMM yyyy"
                                            value={date}
                                            onChange={changeDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {auth.user.data.role === "user" && (
                                        <Button
                                            size="large"
                                            type="primary"
                                            icon={<FormOutlined />}
                                            style={{
                                                marginLeft: 20,
                                            }}
                                            onClick={() => {
                                                let formatted_date =
                                                    date.getFullYear() +
                                                    "-" +
                                                    (date.getMonth() + 1) +
                                                    "-" +
                                                    date.getDate();
                                                history.push(
                                                    `/booking/add/${formatted_date}`
                                                );
                                            }}
                                            danger
                                        >
                                            จองการบิน
                                        </Button>
                                    )}
                                </Space>
                            </div>
                        </Col>
                    </Row>
                    <BookingTable
                        user={auth.user.data}
                        data={booking.table}
                        remove={remove}
                    />
                </Route>
                <Route path={`${path}/add/:date`}>
                    <BookingDetail />
                </Route>
                <Route path={`${path}/:bookingId`}>
                    <BookingDetail />
                </Route>
            </Switch>
        </div>
    );
}
