/* eslint-disable no-extend-native */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Row, Col, Form, Button, Space, Input, DatePicker, Modal } from "antd";
import {
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { useNotification } from "../hooks/useNotification";
import { useLoading } from "../hooks/useLoading";

import { period } from "../AppConfigs";
import { alertMsg } from "../AppConfigs";

import "../css/notification.css";

const { confirm } = Modal;

const { RangePicker } = DatePicker;

Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

export function Notification() {
    let notification = useNotification();
    let loading = useLoading();

    useEffect(() => {
        notification.getNotification();
    }, []);

    const [form] = Form.useForm();
    useEffect(() => {
        console.log(notification.detail)
        form.setFieldsValue(notification.detail);
    }, [form, notification.detail]);

    const cancel = () => {
        confirm({
            title: alertMsg.confirmCancel,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                loading.setLoading(true);
                notification.getNotification();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const onFinish = (values) => {
        confirm({
            title: alertMsg.confirmSave,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                loading.setLoading(true);
                notification.saveNotification(values.message, values.dateRange);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    useEffect(() => {
        if (notification.saveStatus === true) {
            Modal.success({
                content: alertMsg.saveSucces,
            });
            loading.setLoading(false);
            notification.clearStatus();
        } else if (notification.saveStatus === false) {
            Modal.error({
                content: alertMsg.saveFail,
            });
            loading.setLoading(false);
            notification.clearStatus();
        }
    }, [notification.saveStatus]);


    return (
        <div className="notification">
            <Form  
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                layout="horizontal" 
                form={form} 
                onFinish={onFinish} 
                name="notification">
                
                <Form.Item
                    label="ข้อความ"
                    name="message"
                    rules={[
                        {
                            required: false
                        },
                    ]}
                >
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    label="วันที่เริ่มต้น - สิ้นสุด"
                    name="dateRange"
                    rules={[
                        {
                            required: false
                        },
                    ]}
                >
                    <RangePicker />
                </Form.Item>
                <Form.Item
                    style={{
                        marginBottom: 0,
                        textAlign: "center",
                    }}
                >
                    <Space>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                        >
                            Save
                        </Button>
                        <Button
                            size="large"
                            type="primary"
                            danger
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
}
