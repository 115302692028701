import React, { useEffect } from "react";

import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Select,
    Typography,
    Space,
    Modal,
} from "antd";
import {
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
    DatePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { aircraftType, period, mission, area, alertMsg } from "../AppConfigs";

import { useParams, useHistory } from "react-router-dom";
import { useBooking } from "../hooks/useBooking";
import { useLoading } from "../hooks/useLoading";
import { useAuth } from "../hooks/useAuth";

import moment from "moment";

const { Option } = Select;
const { Text, Title } = Typography;
const { TextArea } = Input;
const { confirm } = Modal;

const DatePickerInput = ({ value = {}, onChange }) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/* <DatePicker
                autoOk
                orientation="landscape"
                variant="inline"
                openTo="date"
                label="Date Picker : "
                format="dd MMMM yyyy"
                value={value}
                onChange={onChange}
            /> */}
            <KeyboardDatePicker
                clearable
                value={value}
                onChange={onChange}
                format="dd/MM/yyyy"
            />
        </MuiPickersUtilsProvider>
    );
};

export default function BookingDetail() {
    let { bookingId, date } = useParams();
    let history = useHistory();
    let booking = useBooking();
    let loading = useLoading();
    let auth = useAuth();

    useEffect(() => {
        var cDate = new Date();
        var hours = cDate.getHours();
        var addDays = 0;

        if (hours >= 16) {
            addDays = 1;
        }

        if (date) form.setFieldsValue({ date: cDate.addDays(addDays) });
        if (bookingId) booking.getBookingDetail(bookingId);
    }, []);

    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue(booking.detail);
    }, [form, booking.detail]);

    useEffect(() => {
        if (booking.onProgress !== null) {
            loading.setLoading(false);
            if (booking.onProgress === true) {
                history.push("/booking");
            }
            booking.clear();
        }
    }, [booking.onProgress]);

    function back() {
        confirm({
            title: alertMsg.confirmCancel,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                booking.clear();
                return history.push("/booking");
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    function onFinish(values) {
        confirm({
            title: alertMsg.confirmSave,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                loading.setLoading(true);
                if (bookingId) {
                    booking.updateBooking(auth.user.data.id, bookingId, values);
                } else {
                    booking.saveBooking(auth.user.data.id, values);
                }
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    return (
        <React.Fragment>
            <Row align="left">
                <Col xs={24} xl={24}>
                    <Form
                        name="user-detail"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        layout="horizontal"
                        initialValues={booking.detail}
                        form={form}
                        onFinish={onFinish}
                    >
                        <Form.Item>
                            <Button
                                type="primary"
                                icon={
                                    <ArrowLeftOutlined
                                        style={{ color: "white" }}
                                    />
                                }
                                size="large"
                                onClick={back}
                            >
                                ตารางการจองบิน
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Title level={4}>ข้อมูลการจองบิน</Title>
                        </Form.Item>
                        <Form.Item
                            label="วันที่จอง"
                            name="date"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <DatePickerInput />
                        </Form.Item>
                        <Form.Item
                            label="อากาศยาน"
                            name="aircraftType"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Select allowClear>
                                {aircraftType.map((item) => (
                                    <Option value={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="เวลา"
                            name="period"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Select allowClear>
                                {period.map((item) => (
                                    <Option value={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="ภารกิจการฝึก"
                            name="mission"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Select allowClear>
                                {mission.map((item) => (
                                    <Option value={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="พื้นที่การฝึก/บินเดินทาง"
                            name="area"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Select allowClear>
                                {area.map((item) => (
                                    <Option value={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="ครูฝึก"
                            name="instrutor"
                            rules={[
                                {
                                    required: true,
                                    message: alertMsg.requiredField,
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item label="หมายเหตุ" name="remark">
                            <TextArea rows={2} allowClear />
                        </Form.Item>
                        <Form.Item>
                            <Text type="danger">{alertMsg.msgBooking}</Text>
                        </Form.Item>

                        <Form.Item
                            style={{
                                marginBottom: 0,
                                textAlign: "center",
                            }}
                        >
                            <Space>
                                <Button
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    บันทึก
                                </Button>
                                <Button
                                    size="large"
                                    type="primary"
                                    danger
                                    onClick={back}
                                >
                                    ยกเลิก
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>
    );
}
